import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loading-screen';

// Utility function to create Loadable components
const Loadable = (importFunc) => {
  const LazyComponent = lazy(importFunc);
  return (props) => (
    <Suspense fallback={<LoadingScreen />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

// Account Pages
export const Account = Loadable(() => import('../pages/account'));
export const AccountEdit = Loadable(() => import('../pages/account/Edit'));
export const AccountDuplicate = Loadable(() => import('../pages/account/Duplicate'));
export const AccountDelete = Loadable(() => import('../pages/account/Delete'));

// Bot Pages
export const Bot = Loadable(() => import('../pages/bot'));
export const BotEdit = Loadable(() => import('../pages/bot/Edit'));
export const BotTelegramStatus = Loadable(() => import('../pages/bot/TelegramStatus'));

// Client Pages
export const Client = Loadable(() => import('../pages/client'));
export const ClientEdit = Loadable(() => import('../pages/client/Edit'));
export const ClientDefaultGroups = Loadable(() => import('../pages/client/DefaultGroups'));
export const ClientConfig = Loadable(() => import('../pages/client/Config'));
export const ClientMacros = Loadable(() => import('../pages/client/Macros'));

// Command Pages
export const Command = Loadable(() => import('../pages/command'));
export const CommandEdit = Loadable(() => import('../pages/command/Edit'));
export const CommandLastSent = Loadable(() => import('../pages/command/LastSent'));

// Contact Pages
export const Contact = Loadable(() => import('../pages/contact'));
export const ContactEdit = Loadable(() => import('../pages/contact/Edit'));
export const ContactDelete = Loadable(() => import('../pages/contact/Delete'));
export const ContactContactGroup = Loadable(() => import('../pages/contact/ContactContactGroup'));

// Contact Group Pages
export const ContactGroup = Loadable(() => import('../pages/contact-group'));
export const ContactGroupEdit = Loadable(() => import('../pages/contact-group/Edit'));
export const ContactGroupContacts = Loadable(() => import('../pages/contact-group/Contacts'));

// Dashboard Page
export const Dashboard = Loadable(() => import('../pages/dashboard'));

// Driver Pages
export const Driver = Loadable(() => import('../pages/driver'));
export const DriverEdit = Loadable(() => import('../pages/driver/Edit'));
export const DriverEditApp = Loadable(() => import('../pages/driver/EditApp'));

// Event Pages
export const Event = Loadable(() => import('../pages/event'));

// Fence Pages
export const Fence = Loadable(() => import('../pages/fence'));
export const FenceEdit = Loadable(() => import('../pages/fence/Edit'));
export const FenceVehicles = Loadable(() => import('../pages/fence/Vehicles'));
export const FenceVehicleWithin = Loadable(() => import('../pages/fence/VehicleWithin'));
export const FenceVehicleWithinReport = Loadable(() => import('../pages/fence/VehicleWithinReport'));

// Generic Command Pages
export const GenericCommand = Loadable(() => import('../pages/generic-command'));
export const GenericCommandEdit = Loadable(() => import('../pages/generic-command/Edit'));

// Generic Command Package Pages
export const GenericCommandPackage = Loadable(() => import('../pages/generic-command-package'));

// Grid Pages
export const Grid = Loadable(() => import('../pages/grid'));
export const GridEdit = Loadable(() => import('../pages/grid/Edit'));
export const GridUsers = Loadable(() => import('../pages/grid/Users'));
export const GridColumns = Loadable(() => import('../pages/grid/Columns'));

// Group Pages
export const Group = Loadable(() => import('../pages/group'));
export const GroupEdit = Loadable(() => import('../pages/group/Edit'));
export const GroupEditAutoReport = Loadable(() => import('../pages/group/EditAutoReport'));
export const GroupUsers = Loadable(() => import('../pages/group/Users'));
export const GroupVehicles = Loadable(() => import('../pages/group/Vehicles'));
export const GroupSendMessage = Loadable(() => import('../pages/group/SendMessage'));
export const GroupSpeedReport = Loadable(() => import('../pages/group/SpeedReport'));
export const GroupEventTypeHeatmap = Loadable(() => import('../pages/group/EventTypeHeatmap'));

// IdleTime Page
export const IdleTime = Loadable(() => import('../pages/idletime'));

// Import Page
export const Import = Loadable(() => import('../pages/import'));

// Inhibition Pages
export const Inhibition = Loadable(() => import('../pages/inhibition'));
export const InhibitionEdit = Loadable(() => import('../pages/inhibition/Edit'));
export const InhibitionDelete = Loadable(() => import('../pages/inhibition/Delete'));

// Insurer Pages
export const Insurer = Loadable(() => import('../pages/insurer'));
export const InsurerEdit = Loadable(() => import('../pages/insurer/Edit'));

// Insurance Pages
export const Insurance = Loadable(() => import('../pages/insurance'));
export const InsuranceEdit = Loadable(() => import('../pages/insurance/Edit'));

// Login Page
export const LoginPage = Loadable(() => import('../pages/LoginPage'));

// Kind Event Pages
export const KindEvent = Loadable(() => import('../pages/kind-of-event'));
export const KindEventEdit = Loadable(() => import('../pages/kind-of-event/Edit'));
export const KindEventMarkReadByKind = Loadable(() => import('../pages/kind-of-event/MarkReadByKind'));
export const KindEventRiskManagement = Loadable(() => import('../pages/kind-of-event/RiskManagement'));

// Kind Marker Pages
export const KindMarker = Loadable(() => import('../pages/kind-of-marker'));
export const KindMarkerEdit = Loadable(() => import('../pages/kind-of-marker/Edit'));

// Kind Occurrence Pages
export const KindOccurrence = Loadable(() => import('../pages/kind-of-occurrence'));
export const KindOccurrenceEdit = Loadable(() => import('../pages/kind-of-occurrence/Edit'));

// Kind Operation Pages
export const KindOperation = Loadable(() => import('../pages/kind-of-operation'));
export const KindOperationEdit = Loadable(() => import('../pages/kind-of-operation/Edit'));

// Kind Risk Pages
export const KindRisk = Loadable(() => import('../pages/kind-of-risk'));
export const KindRiskEdit = Loadable(() => import('../pages/kind-of-risk/Edit'));

// Kind Truck Body Pages
export const KindTruckBody = Loadable(() => import('../pages/kind-of-truck-body'));
export const KindTruckBodyEdit = Loadable(() => import('../pages/kind-of-truck-body/Edit'));

// Kind User Pages
export const KindUser = Loadable(() => import('../pages/kind-of-user'));
export const KindUserEdit = Loadable(() => import('../pages/kind-of-user/Edit'));
export const KindUserMenuItem = Loadable(() => import('../pages/kind-of-user/MenuItem'));
export const KindUserDuplicate = Loadable(() => import('../pages/kind-of-user/Duplicate'));
export const KindUserDelete = Loadable(() => import('../pages/kind-of-user/Delete'));
export const KindUserKindOfEvents = Loadable(() => import('../pages/kind-of-user/KindOfEvent'));

// Macro Pages
export const Macro = Loadable(() => import('../pages/macro'));
export const MacroEdit = Loadable(() => import('../pages/macro/Edit'));

// Macro Item Pages
export const MacroItem = Loadable(() => import('../pages/macro_item'));
export const MacroItemInsertBy = Loadable(() => import('../pages/macro_item/InsertBy'));
export const MacroItemEdit = Loadable(() => import('../pages/macro_item/Edit'));
export const MacroItemDelete = Loadable(() => import('../pages/macro_item/Delete'));

// Map Pages
export const Map = Loadable(() => import('../pages/map'));
export const MapMarkerViewer = Loadable(() => import('../pages/map/markerViewer'));
export const MapTripTester = Loadable(() => import('../pages/map/tripTester'));

// Mapserver Pages
export const Mapserver = Loadable(() => import('../pages/mapserver_api'));
export const MapserverEdit = Loadable(() => import('../pages/mapserver_api/Edit'));

// Message Page
export const Message = Loadable(() => import('../pages/message'));

// Occurrence Pages
export const Occurrence = Loadable(() => import('../pages/occurrence'));
export const OccurrenceEdit = Loadable(() => import('../pages/occurrence/Edit'));
export const OccurrenceRead = Loadable(() => import('../pages/occurrence/Read'));

// Platform Pages
export const Platform = Loadable(() => import('../pages/platform'));
export const PlatformEdit = Loadable(() => import('../pages/platform/Edit'));

// Procedure Pages
export const Procedure = Loadable(() => import('../pages/procedure'));
export const ProcedureEdit = Loadable(() => import('../pages/procedure/Edit'));

// Reference Pages
export const Reference = Loadable(() => import('../pages/reference'));
export const ReferenceEdit = Loadable(() => import('../pages/reference/Edit'));
export const ReferenceKindOfEvent = Loadable(() => import('../pages/reference/KindOfEvent'));
export const ReferenceTracking = Loadable(() => import('../pages/reference/Tracking'));
export const ReferenceTrigger = Loadable(() => import('../pages/reference/Trigger'));
export const ReferenceLatLng = Loadable(() => import('../pages/reference/LatLng'));

// Risk Management Pages
export const RiskManagement = Loadable(() => import('../pages/risk-management'));
export const RiskManagementEdit = Loadable(() => import('../pages/risk-management/Edit'));
export const RiskManagementDuplicate = Loadable(() => import('../pages/risk-management/Duplicate'));
export const RiskManagementDelete = Loadable(() => import('../pages/risk-management/Delete'));

// Risk Management Events Pages
export const RiskManagementEvents = Loadable(() => import('../pages/risk-management-events'));
export const RiskManagementEventsEdit = Loadable(() => import('../pages/risk-management-events/Edit'));
export const RiskManagementEventsManager = Loadable(() => import('../pages/risk-management-events/Manager'));

// Scheduled Trip Pages
export const ScheduledTrip = Loadable(() => import('../pages/scheduled-trip'));
export const ScheduledTripConfirm = Loadable(() => import('../pages/scheduled-trip/Confirm'));

// Tracker Pages
export const Tracker = Loadable(() => import('../pages/tracker'));
export const TrackerEdit = Loadable(() => import('../pages/tracker/Edit'));
export const TrackerDelete = Loadable(() => import('../pages/tracker/Delete'));
export const TrackerTestJig = Loadable(() => import('../pages/tracker/TestJig'));
export const TrackerLog = Loadable(() => import('../pages/tracker/Log'));
export const TrackerDebug = Loadable(() => import('../pages/tracker/Debug'));

// Temperature Range Pages
export const TemperatureRange = Loadable(() => import('../pages/temperature-range'));
export const TemperatureRangeEdit = Loadable(() => import('../pages/temperature-range/Edit'));

// Tracker System Pages
export const TrackerSystem = Loadable(() => import('../pages/tracker/system'));
export const TrackerSystemEdit = Loadable(() => import('../pages/tracker/system/Edit'));
export const TrackerSystemProduct = Loadable(() => import('../pages/tracker/system/product'));

// Tracking Page
export const Tracking = Loadable(() => import('../pages/tracking'));

// Trailer Pages
export const Trailer = Loadable(() => import('../pages/trailer'));
export const TrailerEdit = Loadable(() => import('../pages/trailer/Edit'));

// Transit Time Page
export const Transittime = Loadable(() => import('../pages/transittime'));

// Trip Pages
export const Trip = Loadable(() => import('../pages/trip'));
export const TripAdd = Loadable(() => import('../pages/trip/Add'));
export const TripCancel = Loadable(() => import('../pages/trip/Cancel'));
export const TripFinish = Loadable(() => import('../pages/trip/Finish'));

// User Pages
export const User = Loadable(() => import('../pages/user'));
export const UserEdit = Loadable(() => import('../pages/user/Edit'));
export const UserEditKindOfUser = Loadable(() => import('../pages/user/EditKindOfUser'));
export const UserEditGroup = Loadable(() => import('../pages/user/EditGroup'));
export const UserEditGrid = Loadable(() => import('../pages/user/EditGrid'));
export const UserDelete = Loadable(() => import('../pages/user/Delete'));
export const UserGroups = Loadable(() => import('../pages/user/Groups'));
export const UserGrids = Loadable(() => import('../pages/user/Grids'));
export const UserProfile = Loadable(() => import('../pages/user/Profile'));
export const UserLogout = Loadable(() => import('../pages/user/Logout'));
export const UserLog = Loadable(() => import('../pages/user/Log'));
export const UserSecret = Loadable(() => import('../pages/user/Secret'));

// Vehicle Pages
export const Vehicle = Loadable(() => import('../pages/vehicle'));
export const VehicleEdit = Loadable(() => import('../pages/vehicle/Edit'));
export const VehicleGroups = Loadable(() => import('../pages/vehicle/Groups'));
export const VehicleTrackers = Loadable(() => import('../pages/vehicle/Trackers'));
export const VehicleTrailers = Loadable(() => import('../pages/vehicle/Trailers'));
export const VehicleMercosul = Loadable(() => import('../pages/vehicle/Mercosul'));
export const VehicleLog = Loadable(() => import('../pages/vehicle/Log'));

// Error Pages
export const Page403 = Loadable(() => import('../pages/Page403'));
export const Page404 = Loadable(() => import('../pages/Page404'));

// Example Pages
export const PageOne = Loadable(() => import('../pages/PageOne'));
export const PageTwo = Loadable(() => import('../pages/PageTwo'));

// Test Pages
export const Test = Loadable(() => import('../pages/test'));
export const TestSocket = Loadable(() => import('../pages/test/socket'));

